export const environment = {
  /** アプリバージョン */
  appVersion: '1.1',
  /** バージョン管理ファイル */
  addressVersionUrl: 'https://nissan-mslab-dev-na.com/reserve/v2/image?name=system/namie-version.json',
  /** PWA更新中タイムアウト(ms) */
  pwaUpdatingTimeout: 1000 * 15,

  /** PWA関連のモーダル最短表示時間(ms) */
  pwaDisplayTimeout: 1000 * 2,

  /** PWA更新確認中の操作無効化(true:操作可, false:操作不可) */
  operationDuringPwaUpdate: false,

  /* 環境種別 */
  type: 'spoke',
  setting: {
    /** サービサーURL. */
    servicerApiUrl: 'https://nissan-mslab-dev-na.com/reserve',
    /** 言語. */
    language: 'ja',
    /** ステーションコード. */
    stationCode: '',
    /** 自動起動（保存した設定でアプリが起動）. */
    autostart: false,
    /** エリアインデックス. */
    areaIndex: 1,
    /** サウンド. */
    sound: true,
    /** 通信中ポップアップの固定表示時間（単位：ms）. */
    networkingPopupDispTime: 2000,
    /** 顔認証するまでの待ち時間（単位：ms）. */
    faceLoginWaitingTime: 4000,
    /** 待ち受け画面の表示待ち時間（単位：ms）. */
    showWaitingScreenWaitingTime: 300000,
    /** 予約完了後の予約詳細表示画面の表示待ち時間（単位：ms）. */
    reservationWaitingTime: 5000,
    /** 予約キャンセル画面の待ち時間（単位：ms）. */
    cancelMessageWaitingTime: 3000,
    /** 車両が近くに到着したか判定する時間（単位：ms）. */
    vehicleArrivalIsNearTime: 60000,
    /** 予約一覧を取得する間隔（単位：ms）. */
    reservationsGetInterval: 1000,
    /** アプリ状態監視の間隔（単位：ms）. */
    appStateMonitoringInterval: 1000,

    /** デフォルト乗車人数. */
    defaultRideCount: 1,
    /** 最小乗車人数. */
    minRideCount: 1,
    /** 最大乗車人数. */
    maxRideCount: 8,
    /** 駅表示名. */
    stationDisplayName:"",
    /** カメラ. */
    camera: {
      /** カメラを使用. */
      enabled: true,
      /** カメラを常に起動. */
      startAlways: true,
      /** カメラを常に表示. */
      showAlways: false,
      /** カメラの上からの表示位置（単位：px）. */
      showTop: -100,
      /** カメラの左からの表示位置（単位：px）. */
      showLeft: -70,
      /** カメラの表示幅（単位：px）. */
      showWidth: 1200,
      /** カメラの解像度の縦幅（単位：px）. */
      height: 640,
      /** カメラの解像度の横幅（単位：px）. */
      width: 640,
      /** カメラのフロント（user）とリア（environment）. */
      facingMode: 'user',
      /** カメラの回転角度（反時計周り）（単位：度）. */
      rotate: 90,
      /** カメラの左右反転（1:反転なし, -1:反転あり）. */
      scaleX: 1,
      /** カメラの上下反転（1:反転なし, -1:反転あり）. */
      scaleY: 1,
      /** 顔検出. */
      faceDetect: {
        /** 有効. */
        enabled: true,
        /** 検出画像を常に表示. */
        showCaptureAlways: false,
        /** 検出結果を常に表示. */
        showResultAlways: false,
        /** 検出間隔（単位：ms）. */
        interval: 1000,
        /**
         * 閾値.
         * 閾値を満たす場合、顔検出したとみなす.
         */
        threshold: {
          /**
           * スコア.
           * 有効な範囲 : 0-1
           */
          score: 0.0,
          /** Box. */
          box: {
            /**
             * 有効な範囲 : 0-camera.height
             */
            height: {
              min: 0,
              max: 640
            },
            /**
             * 有効な範囲 : 0-camera.width
             */
            width: {
              min: 0,
              max: 640
            },
            /**
             * 有効な範囲 : 0-camera.height
             */
            top: {
              min: 0,
              max: 640
            },
            /**
             * 有効な範囲 : 0-camera.width
             */
            left: {
              min: 0,
              max: 640
            }
          }
        }
      }
    }
  },
  /** エリアリスト. */
  areas: [
    {
      name: 'NATC'
    },
    {
      name: '浪江'
    }
  ],
  stations: {
    /** 道の駅なみえ. */
    NH0101: { furigana: 'みちのえきなみえ' },
    /** JR浪江駅. */
    NH0103: { furigana: 'JRなみええき' },
    /** 浪江郵便局. */
    NH0104: { furigana: 'なみえゆうびんきょく' },
    /** イオン浪江店. */
    NH0105: { furigana: 'いおんなみえてん' },
    /** 浪江町役場. */
    NH0106: { furigana: 'なみえまちやくば' },
    /** スポーツセンター. */
    NH0113: { furigana: 'すぽーつせんたー' },
    /** 新町ふれあい広場. */
    NH0114: { furigana: 'しんまちふれあいひろば' }
  },
  /** サービサーURLリスト. */
  servicerApiUrls: [
    'https://nissan-mslab-dev-na.com/reserve'
  ],
  /** 時刻表URL. */
  timeScheduleUrl: '/v2/image?name=system/opening_hours.txt',

  /** メッセージURL. */
  testMassage: '/assets/message.txt',

  /**
   * ホーム画面メッセージ取得先URL.
   *
   * - 本番リリース時 :
   */
   addressMessageBoardUrl: 'https://nissan-mslab-dev-na.com/reserve/v2/image?name=system/message_board_minidigistop.html',

  /** 住所リストURL. */
  stationCsvUrl: '/v2/image?name=stop_point/data.csv',

  addressCsvUrl: 'https://nissan-mslab-dev-na.com/reserve/v2/image?name=stop_point/data.csv',

  stopPointImageUrl:"/v2/image?name=stop_point/img/",

  /* マップの切り替え.
  *
  * - none : マップ使用しない
  * - osm : OpenStreetMap
  * - google : Google Map
  *    `index.html` に `<script src="https://maps.googleapis.com/maps/api/js?key=${GoogleMapApikey}" async defer></script>` を指定してください。
  */
  map: 'google',
  /** デフォルト乗車人数. */
  defaultRideCount: 1,
  /** 最小乗車人数. */
  minRideCount: 1,
  /** 最大乗車人数. */
  maxRideCount: 8,
  /** デフォルト荷物数. */
  defaultCargoCount: 1,
  /** 最小荷物数. */
  minCargoCount: 1,
  /** 最大荷物数. */
  maxCargoCount: 3,
  /** 必須乗車地付近にいるかを判断するインターバル(ミリ秒). */
  requiredStationNearInterval: 30000,
  /** 歩行速度（単位 : km/h）デフォルト : 4.8km/h（時速） ※分速の場合、80m/min */
  walkVelocity: 4.8,


  /** オートコンプリートの有効化. */
  isEnabledAutocomplete: false,
  /** BLEの有効. */
  isEnabledBle: false,
  /** ServiceWorkerModule の登録.（PWA 実行時に true） */
  serviceWorkerRegister: true,

  /** 地図初期表示（緯度）. */
  mapInitialStateLat: 37.496098500,
  /** 地図初期表示（経度）. */
  mapInitialStateLng: 141.000272600,
  /** 地図初期表示（ズーム）. */
  mapInitialStateZoom: 20,

  csvLoader: null,

  DoStationCodes: null,

  consttables : {
    userStations:null,
    timeschedule:null,
    datacsv:null,
    puStations:null,
    nextLoadDate:0
  },

  stationtables : {
    DoStationCodes:null,
    stationSession:null,
    assignName:"",
    displayName:"",
  },
  stationImageAlias:[
    'NC0101',
    'NC0202',
    'NC0203',
    'NC0205',
    'NS0501',
    'NS1304',
  ],
  staticTableLoaded:false,

  // doStationCodeを取得する際に使用するユーザーID　N070
  APIaccessUserId : 150,

  currentGuest : null,

  buildVersion : "2411060557",

  pageReloading : false
};
